@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  html,
  body,
  #root {
    @apply tw-h-full tw-overflow-hidden tw-font-proxima_novaregular;
  }
}

@font-face {
  font-family: 'proxima_novaregular';
  src:
    url('./assets/fonts/proximanova-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/proximanova-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_novabold';
  src:
    url('./assets/fonts/proximanova-bold-webfont.woff2') format('woff2'),
    url('./assets/fonts/proximanova-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_novaextrabold';
  src:
    url('./assets/fonts/proximanova-extrabold-webfont.woff2') format('woff2'),
    url('./assets/fonts/proximanova-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
